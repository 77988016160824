'use client'

import { datadogLogs } from '@datadog/browser-logs'
import { Inter } from 'next/font/google'
import { useEffect } from 'react'

import '~/ui/styles/globals.css'
import { cn } from '~/ui/lib/utils'
import ErrorTemplate from '~/modules/error-template'

const inter = Inter({
	subsets: [
		'latin',
	],
})

const GlobalError = ({
	error,
	reset,
}: {
	error: Error & { digest?: string }
	reset: () => void
}) => {
	useEffect(() => {
		datadogLogs.logger.error('Error occured', {}, error)
	}, [
		error,
	])

	const htmlClassList = cn(
		'h-full',
		inter.className,
	)

	return (
		<html
			lang="en"
			className={htmlClassList}
		>
			<body>
				<ErrorTemplate
					type="error"
					message="Something went wrong!"
					onClick={() => {
						reset()
					}}
				/>
			</body>
		</html>
	)
}

export default GlobalError
